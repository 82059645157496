:root {
  --teal: rgb(30, 108, 114);
  --teal-dark: rgb(40, 144, 152);
}

.white-bg {
  background-color: rgb(255, 255, 255);
}

.teal {
  color: var(--teal);
}

.white {
  color: #ffffff;
}

.teal-bg {
  background-color: var(--teal);
}

.btn-teal {
  background-color: var(--teal);
  color: #ffffff;
}

.btn-teal:hover,
.btn-teal:focus {
  background-color: var(--teal-dark);
  color: #ffffff;
  font-weight: bold;
}

#feature_list {
  list-style-type: url('../public/check2.svg') !important;
  list-style-image: url('../public/check2.svg') !important;
}

.faq_list {
  list-style-type: style type;
}

.question {
  font-weight: bold;
}

.footnote>p {
  font-size: small;
}